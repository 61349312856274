import { Component } from '@angular/core';
import { AuthModule } from '@app/auth/auth.module';
import { AuthFooterComponent } from '@app/auth/footer/footer.component';
import { SecurityLinkComponent } from '@app/auth/security-link/security-link.component';

@Component({
  selector: 'app-maintenance-page',
  imports: [AuthModule, AuthFooterComponent, SecurityLinkComponent],
  templateUrl: './maintenance-page.component.html',
  styleUrl: './maintenance-page.component.scss',
  standalone: true,
})
export class MaintenancePageComponent {}
